import { Component, Input } from '@angular/core';
import { ArticleDetail } from '@flink-legacy/core/declarations/article-detail.interface';
import { Comment } from '@flink-legacy/core/declarations/comment.interface';
import { CommentItemComponent } from './comment-item/comment-item.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'fl-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  imports: [CommentItemComponent, NgFor, NgIf]
})
export class CommentsComponent {
  @Input() post: ArticleDetail;

  constructor() {}

  displayComment(comment: Comment) {
    return !comment.hidden;
  }

  comments() {
    return this.post.comments;
  }
}
