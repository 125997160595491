import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslationModule } from '@flink-legacy/core/translation/translation.module';

import { PipesModule } from './pipes/pipes.module';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { LayoutModule } from '@flink-legacy/core/layout/layout.module';
import { InfinitePaginationModule } from './components/infinite-pagination/infinite-pagination.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    ComponentsModule,
    TranslationModule,
    DirectivesModule,
    LayoutModule,
    InfinitePaginationModule
  ],
  exports: [
    ComponentsModule,
    TranslationModule,
    DirectivesModule,
    LayoutModule,
    PipesModule,
    InfinitePaginationModule
  ]
})
export class SharedModule {}
