import { Injectable, Injector } from '@angular/core';
import { Tenant } from '@flink-legacy/core/declarations/tenant.interface';
import {
  DefineRepositoryParams,
  RepositoryAbstract
} from './repository.abstract';

export type TenantRepositoryParams = DefineRepositoryParams<
  Tenant,
  {
    all: Tenant[];
  }
>;

@Injectable({
  providedIn: 'root'
})
export class TenantRepository extends RepositoryAbstract<TenantRepositoryParams> {
  constructor(injector: Injector) {
    super('tenants', injector);
  }
}
