import { MessageNotificationsWithCount } from '@flink-legacy/core/declarations/message-notifications.interface';

export interface MessageNotificationsState {
  messageNotifications: MessageNotificationsWithCount;
}

export const initialState: MessageNotificationsState = {
  messageNotifications: {
    services: {
      count: 0,
      notifications: []
    },
    community: {
      count: 0,
      notifications: []
    }
  }
};
