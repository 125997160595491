import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Group } from '@flink-legacy/interfaces/group';
import {
  GroupRepository,
  GroupRepositoryParams
} from '@flink-legacy/shared/repositories/group.repository';
import { Fetchable } from '@flink-legacy/core/declarations/fetchable.interface';
import { PaginatedAbstractService } from './paginated.abstract.service';

@Injectable({
  providedIn: 'root'
})
export class GroupService extends PaginatedAbstractService<GroupRepositoryParams> {
  private myGroups: BehaviorSubject<Fetchable<Group[]>> = new BehaviorSubject({
    state: 'loading'
  });

  private recommendedGroups: BehaviorSubject<Fetchable<Group[]>> =
    new BehaviorSubject({
      state: 'loading'
    });

  get myGroups$() {
    return this.myGroups.asObservable();
  }

  get recommendedGroups$() {
    return this.recommendedGroups.asObservable();
  }

  constructor(private groupRepository: GroupRepository) {
    super(groupRepository);
  }

  fetchMyGroups() {
    this.myGroups.next({ state: 'loading', data: this.myGroups.value.data });

    this.groupRepository.getMyGroups().subscribe(
      groups => {
        this.myGroups.next({ state: 'ready', data: groups });
      },
      error => this.myGroups.next({ state: 'error', error })
    );
  }

  fetchRecommendedGroups() {
    this.recommendedGroups.next({
      state: 'loading',
      data: this.recommendedGroups.value.data
    });

    this.groupRepository.getRecommendedGroups().subscribe(
      groups => {
        this.recommendedGroups.next({ state: 'ready', data: groups });
      },
      error => this.recommendedGroups.next({ state: 'error', error })
    );
  }
}
