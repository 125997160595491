import {
  SET_CURRENT_NEWSFEED_FILTERS,
  SetCurrentNewsfeedFiltersAction
} from './newsfeed.actions';
import { initialState, NewsfeedState } from './newsfeed.state';

export const newsfeedReducers = (
  state: NewsfeedState = initialState,
  action: Actions
): NewsfeedState => {
  let newState: NewsfeedState;
  switch (action.type) {
    case SET_CURRENT_NEWSFEED_FILTERS:
      newState = {
        ...state,
        currentNewsfeedFilters: action.payload
      };
      break;
    default:
      newState = state;
  }

  return newState;
};

export type Actions = SetCurrentNewsfeedFiltersAction;
