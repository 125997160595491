import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { STORAGE_KEYS } from '@flink-legacy/core/declarations/storage-keys.enum';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { from, of, switchMap } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import {
  LOAD_CURRENT_EVENTS_FILTERS,
  SET_CURRENT_EVENTS_FILTERS
} from './events.actions';
import { INITIAL_EVENT_FILTERS } from '@flink-legacy/core/declarations/article.interface';
import { getCurrentEventsFilters } from './events.selectors';

@Injectable()
export class EventsEffects {
  loadCurrentEventsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_CURRENT_EVENTS_FILTERS),
      mergeMap(() =>
        from(
          Preferences.get({ key: STORAGE_KEYS.CURRENT_EVENTS_FILTERS })
        ).pipe(
          map(v => JSON.parse(v.value)),
          map(t => ({
            type: SET_CURRENT_EVENTS_FILTERS,
            payload: t ?? INITIAL_EVENT_FILTERS
          }))
        )
      )
    )
  );

  saveCurrentEventsFiltersToStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_CURRENT_EVENTS_FILTERS),
        concatLatestFrom(() => this.store.select(getCurrentEventsFilters)),
        switchMap(([, f]) => {
          if (f !== null) {
            return from(
              Preferences.set({
                key: STORAGE_KEYS.CURRENT_EVENTS_FILTERS,
                value: JSON.stringify(f)
              })
            );
          } else {
            return of(f);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store
  ) {}
}
