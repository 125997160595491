import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'fl-warning-card',
  templateUrl: './warning-card.component.html',
  styleUrls: ['./warning-card.component.scss'],
  imports: [IonicModule, TranslateModule, SvgIconComponent]
})
export class WarningCardComponent {
  @Input() title: string;
  @Input() content: string;

  constructor() {}
}
