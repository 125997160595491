import { Inject, Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { STORAGE_KEYS } from '@flink-legacy/core/declarations/storage-keys.enum';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, tap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { logout, setCurrentUser } from './user.actions';
import { DOCUMENT } from '@angular/common';
import {
  SubscribeToBENotifications,
  UnsubscribeFromBENotifications
} from '../message-notifications-state/message-notifications.actions';
import { setUser } from '@sentry/angular';

@Injectable()
export class UserEffects {
  logout$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      tap(async () => {
        await Preferences.remove({ key: STORAGE_KEYS.AUTH });
        setUser(null);
      }),
      map(() => UnsubscribeFromBENotifications())
    )
  );

  setCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrentUser),
      map(() => SubscribeToBENotifications())
    )
  );

  setHighContrast$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setCurrentUser),
        filter(u => u != null),
        tap(user => {
          const style = this.document.documentElement.style;
          if (user.high_contrast) {
            style.setProperty(
              '--ion-color-input-border',
              'var(--ion-color-medium)'
            );
            style.setProperty(
              '--segment-button-color',
              'var(--ion-color-white)'
            );
            style.setProperty(
              '--poll-option-background',
              'var(--ion-color-white)'
            );
          } else {
            style.setProperty(
              '--ion-color-input-border',
              'var(--ion-color-pattensblue)'
            );
            style.removeProperty('--segment-button-color');
            style.removeProperty('--poll-option-background');
          }
        })
      ),
    { dispatch: false }
  );

  setSentryUser$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setCurrentUser),
        filter(u => u != null),
        tap(({ id }) => {
          setUser({
            id: String(id)
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    @Inject(DOCUMENT) private document: Document
  ) {}
}
