import {
  INITIAL_NEWSFEED_FILTERS,
  NewsfeedFilters
} from '@flink-legacy/core/declarations/filter.interface';

export interface NewsfeedState {
  currentNewsfeedFilters: NewsfeedFilters;
}

export const initialState: NewsfeedState = {
  currentNewsfeedFilters: INITIAL_NEWSFEED_FILTERS
};
