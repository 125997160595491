import { Injectable } from '@angular/core';
import {
  ReportItemType,
  ReportType
} from '@flink-legacy/core/declarations/report.interface';
import { ReportRepository } from '../repositories/report.repository';
import { NotificationService } from './notification.service';
import { marker as _ } from '@bling-fe/shared/utils';

type ReportParams = {
  itemId?: number;
  itemType?: ReportItemType;
  reportType: ReportType;
  reason: string;
  externalObjectType?: string;
  externalObjectId?: string;
  externalObjectInfo?: string;
};

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(
    private reportRepository: ReportRepository,
    private notificationService: NotificationService
  ) {}

  handleReport({
    itemId,
    itemType,
    reportType,
    reason,
    externalObjectType,
    externalObjectId,
    externalObjectInfo
  }: ReportParams) {
    const translationKey = itemType || externalObjectType;
    this.reportRepository
      .create({
        reportable_id: itemId,
        reportable_type: itemType,
        reason,
        report_type: reportType,
        external_object_type: externalObjectType,
        external_object_id: externalObjectId,
        external_object_info: externalObjectInfo
      })
      .subscribe({
        next: () => {
          const msg =
            reportType === 'block'
              ? `COMMON.LABELS.BLOCKED.${translationKey.toUpperCase()}`
              : `COMMON.LABELS.REPORTED.${translationKey.toUpperCase()}`;
          this.notificationService.success(msg);
        },
        error: err => {
          if (err.status === 409) {
            const errorText =
              reportType === 'block'
                ? `COMMON.LABELS.DUPLICATE_BLOCKED_ERROR.${translationKey.toUpperCase()}`
                : `COMMON.LABELS.DUPLICATE_REPORTED_ERROR.${translationKey.toUpperCase()}`;
            this.notificationService.error(errorText);
          } else {
            throw err;
          }
        }
      });
  }
}

// blocked success
_('COMMON.LABELS.BLOCKED.ARTICLE');
_('COMMON.LABELS.BLOCKED.GROUP');
_('COMMON.LABELS.BLOCKED.USER');
_('COMMON.LABELS.BLOCKED.COMMENT');
// reported success
_('COMMON.LABELS.REPORTED.ARTICLE');
_('COMMON.LABELS.REPORTED.GROUP');
_('COMMON.LABELS.REPORTED.USER');
_('COMMON.LABELS.REPORTED.COMMENT');
// blocked duplicate error
_('COMMON.LABELS.DUPLICATE_BLOCKED_ERROR.ARTICLE');
_('COMMON.LABELS.DUPLICATE_BLOCKED_ERROR.GROUP');
_('COMMON.LABELS.DUPLICATE_BLOCKED_ERROR.USER');
_('COMMON.LABELS.DUPLICATE_BLOCKED_ERROR.COMMENT');
// reported duplicate error
_('COMMON.LABELS.DUPLICATE_REPORTED_ERROR.ARTICLE');
_('COMMON.LABELS.DUPLICATE_REPORTED_ERROR.GROUP');
_('COMMON.LABELS.DUPLICATE_REPORTED_ERROR.USER');
_('COMMON.LABELS.DUPLICATE_REPORTED_ERROR.COMMENT');
