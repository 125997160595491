import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RedirectIfLoggedIn } from './redirect-if-logged-in.guard';
import { AuthenticatedGuard } from './authenticated.guard';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: [],
  providers: [AuthenticatedGuard, RedirectIfLoggedIn]
})
export class GuardsModule {}
