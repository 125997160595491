import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NewLineToBrPipe } from './new-line-to-br.pipe';
import { TruncatePipe } from './truncate.pipe';
import { ElapsedTimePipe } from './elapsed-time.pipe';
import { FlCurrencyPipe } from './fl-currency.pipe';
import { SameDayPipe } from './same-day.pipe';
import { ChatDateDisplayPipe } from './chat-date-display.pipe';
import { MarkdownToHtmlPipe } from './markdown-to-html.pipe';
import { Sanitize } from './sanitize.pipe';
import { DisplayUrl } from './display-url.pipe';
import { IhCurrencyPipe } from './ih-currency.pipe';
import { LinkyPipe } from '@flink-legacy/shared/pipes/linky.pipe';

@NgModule({
  declarations: [
    ChatDateDisplayPipe,
    NewLineToBrPipe,
    TruncatePipe,
    ElapsedTimePipe,
    FlCurrencyPipe,
    SameDayPipe,
    MarkdownToHtmlPipe,
    Sanitize,
    DisplayUrl,
    IhCurrencyPipe,
    LinkyPipe
  ],
  imports: [CommonModule],
  providers: [DatePipe, DecimalPipe],
  exports: [
    ChatDateDisplayPipe,
    NewLineToBrPipe,
    TruncatePipe,
    ElapsedTimePipe,
    FlCurrencyPipe,
    SameDayPipe,
    MarkdownToHtmlPipe,
    Sanitize,
    DisplayUrl,
    IhCurrencyPipe,
    LinkyPipe
  ]
})
export class PipesModule {}
