<ion-datetime
  presentation="date"
  preferWheel="false"
  multiple
  [value]="currentUserSelection"
  [min]="minDate"
  [max]="maxDate"
  (ionChange)="handleDateChange($event)"
  [doneText]="'COMMON.LABELS.CONFIRM' | translate"
  [cancelText]="'COMMON.LABELS.CANCEL' | translate"
  [highlightedDates]="highlightedDates"
  [isDateEnabled]="isDateAvailable"
/>

<ion-row class="d-flex flex-col ion-justify-content-center">
  <ion-button
    class="confirmDateRange"
    size="default"
    fill="clear"
    [disabled]="disabledConfirm"
    (click)="confirm()"
    data-cy="calendar-confirm-pick"
  >
    {{
      "PAGES.ADD_NEW.DAILY_RESERVATION.COMFIRM_MODAL.COMFIRM_DATE_BTN"
        | translate
    }}
  </ion-button>
</ion-row>
