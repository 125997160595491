import { Injectable, Injector } from '@angular/core';
import {
  DocumentCategory,
  DocumentCategoryDetail,
  DocumentCategoryFilterParams,
  DocumentCategoryParams
} from '@flink-legacy/core/declarations/document-category.interface';

import {
  DefineRepositoryParams,
  RepositoryAbstract
} from '@flink-legacy/shared/repositories/repository.abstract';

export type DocumentCategoryRepositoryParams = DefineRepositoryParams<
  DocumentCategoryDetail,
  {
    all: DocumentCategory[];
    queryParams: DocumentCategoryFilterParams;
    createParams: DocumentCategoryParams;
  }
>;

@Injectable({
  providedIn: 'root'
})
export class DocumentCategoryRepository extends RepositoryAbstract<DocumentCategoryRepositoryParams> {
  constructor(injector: Injector) {
    super('document_categories', injector);
  }
}
