import { Environment } from '@bling-fe/shared/env';

export const environment: Environment = {
  name: 'development',
  sentry: '',
  production: false,
  tenant: null,
  apiDomain: 'https://www.admin.dev.flink.coop/api/v1/',
  washMasterUrl: 'https://app.washmaster.ch/',
  // voting app just for abz now
  votingAppUrl: 'https://abstimmungen.abz.dev.flink.coop/',
  websocketUrl: 'wss://www.admin.dev.flink.coop/websocket'
};
