import { createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';
import {
  User,
  UserFilterParams
} from '@flink-legacy/core/declarations/user.interface';
import { initialUserFilterState } from './user.state';

export interface UserState {
  currentUser: User;
}

export const initialState: UserState = {
  currentUser: null
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.setCurrentUser, (state, user) => ({
    ...state,
    currentUser: user
  })),
  on(UserActions.logout, state => ({
    ...state,
    currentUser: null
  }))
);

export const userFilterReducer = createReducer(
  initialUserFilterState,
  on(
    UserActions.setUsersFilter,
    (state = initialUserFilterState, userFilterParams: UserFilterParams) => {
      return {
        ...state,
        usersFilterState: { ...state.usersFilterState, ...userFilterParams }
      };
    }
  )
);
