<div class="avatar-wrapper">
  <ion-avatar
    (click)="navigateToUserProfile()"
    class="overflow-hidden"
    [ngClass]="{ 'cursor-pointer': clickable }"
    [style.height]="size + 'px'"
    [style.width]="size + 'px'"
  >
    <ng-container *ngIf="user.avatar_url; else initialsTemplate">
      <img
        [src]="user.avatar_url"
        alt="Avatar profile picture"
      />
    </ng-container>

    <ng-template #initialsTemplate>
      <svg viewBox="0 0 1 1">
        <mask [id]="'avatarsRadiusMask' + uniqueId">
          <rect
            width="1"
            height="1"
            rx="0"
            ry="0"
            x="0"
            y="0"
            fill="#fff"
          />
        </mask>
        <g [attr.mask]="'url(#avatarsRadiusMask' + uniqueId + ')'">
          <rect
            width="1"
            height="1"
            fill="#F5F6F8"
          />
          <text
            x="50%"
            y="50%"
            style="
              font:
                600 0.4px Inter,
                Arial,
                sans-serif;
            "
            fill="#88889C"
            text-anchor="middle"
            dy="0.15"
          >
            {{ initials }}
          </text>
        </g>
      </svg>
    </ng-template>
  </ion-avatar>
  <ng-container *ngIf="showOnlineIndicator">
    <div
      class="online-indicator online-indicator--{{
        user.is_online ? 'online' : 'offline'
      }}"
      [ngClass]="{
        'online-indicator--lg': size >= 80,
        'online-indicator--xxl': size >= 100
      }"
    ></div>
  </ng-container>
</div>
