import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { Preferences } from '@capacitor/preferences';
import { firstValueFrom, from, Observable } from 'rxjs';
import { filter, first, map, mapTo, switchMap, take } from 'rxjs/operators';
import { environment } from '@bling-fe/shared/env';
import { User } from '@flink-legacy/interfaces/user';
import { Store } from '@ngrx/store';
import { UserRepository } from '@flink-legacy/shared/repositories/user.repository';
import { AuthRepository } from '@flink-legacy/shared/repositories/auth.repository';
import { STORAGE_KEYS } from '@flink-legacy/core/declarations/storage-keys.enum';
import { AuthData } from '@flink-legacy/core/declarations/auth-data.interface';
import { getCurrentTenant } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import {
  logout,
  setCurrentUser
} from '@flink-legacy/core/states/user-state/user.actions';
import { UserState } from '@flink-legacy/core/states/user-state/user.reducers';
import { TenantState } from '@flink-legacy/core/states/tenant-state/tenant.state';
import { Tenant } from '@flink-legacy/core/declarations/tenant.interface';
import { PushNotificationService } from './push-notification.service';
import { DeviceRegisterService } from './device-register.service';
import { Capacitor } from '@capacitor/core';
import { SavePassword } from 'capacitor-ios-autofill-save-password';

export interface LoginRequest {
  email: string;
  password: string;
}

export interface ApiResponse {
  status?: string;
  success?: boolean;
  statusText?: string;
  data?: User;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    private userStore: Store<UserState>,
    private tenantStore: Store<TenantState>,
    private store: Store,
    private router: Router,
    private userRepository: UserRepository,
    private authRepository: AuthRepository,
    private pushNotificationService: PushNotificationService,
    private deviceRegisterService: DeviceRegisterService
  ) {}

  async init(): Promise<void> {
    // Firstly load auth data from Preferences
    const rawAuthData = await Preferences.get({ key: STORAGE_KEYS.AUTH });
    // If there are some ...
    if (rawAuthData.value) {
      // ... then wait for current tenant to be setup
      await this.store
        .select(getCurrentTenant)
        .pipe(
          filter(x => x !== 'loading'),
          first()
        )
        .toPromise();

      // ... and check token validity
      await this.validateToken();
    }
  }

  async login(credentials: LoginRequest) {
    try {
      await this.authRepository.signIn(credentials).pipe(take(1)).toPromise();
      // TODO result.data contains user which has different shape from what we expect,
      // so call to current user is made instead
      try {
        const user = await this.userRepository
          .getCurrentUser()
          .pipe(take(1))
          .toPromise();

        if (!this.userAuthorized(user)) {
          this.logout();
          throw new Error('unauthorized');
        }

        // save credentials after successful login
        if (Capacitor.getPlatform() === 'ios') {
          await SavePassword.promptDialog({
            username: credentials.email,
            password: credentials.password
          });
        }

        this.userStore.dispatch(setCurrentUser(user));

        // Register device token for Push Notification after login is succesfull
        const pushToken =
          await this.pushNotificationService.getNotificationToken();
        if (pushToken.value) {
          this.deviceRegisterService.store(pushToken.value);
        }
      } catch (e) {
        throw e;
      }
    } catch (e) {
      if (!environment.production) {
        // eslint-disable-next-line no-console
        console.error('Login error: ', e);
      }
      throw e;
    }
  }

  async logout(
    { skipSignOut }: { skipSignOut: boolean } = { skipSignOut: false }
  ) {
    if (!skipSignOut) {
      try {
        if (Capacitor.isNativePlatform()) {
          await this.deviceRegisterService.deactivate();
        }
        await this.authRepository.signOut().pipe(take(1)).toPromise();
      } catch (error) {
        // Silence
      }
    }

    this.userStore.dispatch(logout());

    this.router.navigate(['/']);
  }

  async validateToken() {
    try {
      const user = await this.userRepository
        .getCurrentUser()
        .pipe(take(1))
        .toPromise();
      this.userStore.dispatch(setCurrentUser(user));
    } catch (error) {
      this.logout({ skipSignOut: true });
    }
  }

  async resetPassword(email: string): Promise<boolean> {
    try {
      const resetPassword = this.tenantStore.select(getCurrentTenant).pipe(
        filter(x => x !== 'loading'),
        first(),
        map(t => t as Tenant),
        map(t => t.tenant_setting.app_frontend_url),
        switchMap(frontendUrl =>
          this.authRepository.resetPassword(
            email,
            `${frontendUrl}/reset-password`
          )
        )
      );

      await firstValueFrom(resetPassword);

      return true;
    } catch (e) {
      if (e.status === 404) {
        // Silence 404 status to not reveal what emails are in database
        return false;
      } else {
        throw e;
      }
    }
  }

  updatePasswordAfterReset(
    password: string,
    authHeaders: Record<string, string | string[]>
  ): Observable<unknown> {
    const requestOptions = {
      headers: new HttpHeaders(authHeaders)
    };

    return this.authRepository
      .updatePassword(password, password, requestOptions)
      .pipe(switchMap(res => from(this.validateToken()).pipe(mapTo(res))));
  }

  setupPasswordToAcceptInvitation(
    invitation_token: string,
    password: string
  ): Observable<User> {
    return this.authRepository.acceptInvitation(invitation_token, password);
  }

  /**
   * If access token is not null, user is considered logged in.
   */
  isLoggedIn(): Observable<boolean> {
    const obs = from(Preferences.get({ key: STORAGE_KEYS.AUTH }));
    return obs.pipe(map(data => !!JSON.parse(data?.value)));
  }

  getAuthData(): Observable<AuthData> {
    const obs = from(Preferences.get({ key: STORAGE_KEYS.AUTH }));
    return obs.pipe(map(data => JSON.parse(data.value) as AuthData));
  }

  private userAuthorized(user): boolean {
    return (
      user.admin ||
      user.resident ||
      user.staff ||
      user.super_admin ||
      user.friend
    );
  }
}
