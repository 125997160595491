import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class RedirectIfLoggedIn {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivateChild(
    _: ActivatedRouteSnapshot,
    __: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authenticationService.isLoggedIn().pipe(
      map(logged => {
        if (logged) {
          return this.router.parseUrl('/home');
        } else {
          return true;
        }
      })
    );
  }
}
