import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthData } from '@flink-legacy/core/declarations/auth-data.interface';
import { STORAGE_KEYS } from '@flink-legacy/core/declarations/storage-keys.enum';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor() {}

  public getAuthHeadersFromResponse(
    data: HttpResponse<unknown> | HttpErrorResponse
  ): void {
    const headers = data.headers;

    const authData: AuthData = {
      accessToken: headers.get('access-token'),
      client: headers.get('client'),
      expiry: headers.get('expiry'),
      tokenType: headers.get('token-type'),
      uid: headers.get('uid')
    };

    if (this.checkAuthData(authData)) {
      Preferences.set({
        key: STORAGE_KEYS.AUTH,
        value: JSON.stringify(authData)
      });
    }
  }

  // Check if auth data are complete and if response token is newer
  private checkAuthData(authData: AuthData): boolean {
    // Are data complete? This effectivelly filters out response headers
    // on request that arent protected so empty values are not stored
    if (
      !authData ||
      !authData?.accessToken ||
      !authData?.client ||
      !authData?.expiry ||
      !authData?.tokenType ||
      !authData?.uid
    ) {
      return false;
    }

    // TODO: Check expiry
    // if (storedAuthData) {
    //   return authData.expiry >= storedAuthData.expiry;
    // }

    return true;
  }
}
