import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';

export type AvatarUser = {
  id: number;
  first_name: string;
  last_name: string;
  avatar_url?: string;
  is_online?: boolean;
};

let uniqueId = 0;

@Component({
  selector: 'bl-avatar',
  imports: [CommonModule, IonicModule],
  templateUrl: './ui-avatar.component.html',
  styleUrls: ['./ui-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiAvatarComponent implements OnInit {
  @Input() user!: AvatarUser;
  /**
   * In pixels.
   */
  @Input() size = 64;
  /**
   * Allows clicks on avatar to navigate to user profile.
   */
  @Input() clickable = true;
  /**
   * Show online indicator in bottom right corner.
   */
  @Input() showOnlineIndicator = true;

  initials = '';

  router = inject(Router);
  cdref = inject(ChangeDetectorRef);

  uniqueId = uniqueId++;

  ngOnInit() {
    const tuple = [this.user.first_name ?? '', this.user.last_name ?? ''];
    this.initials = tuple.map(name => name[0]?.toUpperCase()).join('');
  }

  navigateToUserProfile() {
    if (this.clickable) {
      this.router.navigateByUrl(`/profile/${this.user.id}`);
    }
  }
}
