import {
  ArticleEventFilter,
  INITIAL_EVENT_FILTERS
} from '@flink-legacy/core/declarations/article.interface';

export interface EventsFiltersState {
  currentEventsFilters: ArticleEventFilter;
}

export const initialState: EventsFiltersState = {
  currentEventsFilters: INITIAL_EVENT_FILTERS
};
