import { Component, ElementRef, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { LayoutState } from '@flink-legacy/core/states/layout-state/layout.reducers';
import { getPageTransitionAnimated } from '@flink-legacy/core/states/layout-state/layout.selectors';
import { MessageNotificationsState } from '@flink-legacy/core/states/message-notifications-state/message-notifications.state';
import { ActionCableRepository } from '@flink-legacy/shared/repositories/action-cable.repository';
import { MessageNotificationService } from '@flink-legacy/shared/services/message-notification.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'fl-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  standalone: false
})
export class AppLayoutComponent implements OnInit {
  withoutTabs = true;
  pageTransitionAnimated$: Observable<boolean>;

  // If tabs navigation needs to be hidden on specific routes,
  // enter it here.
  private readonly HIDE_ON_URLS = [
    '/daily-reservable/',
    '/daily-reservation/',
    '/edit/',
    '/group/',
    '/help',
    '/new/',
    '/hourly-reservable/',
    '/post/',
    '/privacy-policy',
    '/profile/',
    '/ticket/',
    '/event',
    '/changelog',
    '/my-profile/delete-account',
    '/terms-of-use',
    '/legal-notice',
    '/contact'
  ];

  constructor(
    public element: ElementRef<HTMLElement>,
    private router: Router,
    private store: Store<LayoutState>,
    private messageNotificationService: MessageNotificationService,
    private actionCableRepository: ActionCableRepository,
    private messageNotificationsStore: Store<MessageNotificationsState>
  ) {}

  ngOnInit() {
    this.handleHide(this.router.url);
    this.onNavigationEvent();

    this.pageTransitionAnimated$ = this.store.select(getPageTransitionAnimated);
  }

  /**
   * Hide tabs if it matches url where tabs should be hidden.
   */
  onNavigationEvent() {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.handleHide(event.url);
      });
  }

  handleHide(currentUrl: string) {
    this.withoutTabs = this.HIDE_ON_URLS.some(url => currentUrl.includes(url));
  }
}
