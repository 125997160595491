import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Tenant } from '@flink-legacy/core/declarations/tenant.interface';
import { getCurrentTenant } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { TenantState } from '@flink-legacy/core/states/tenant-state/tenant.state';
import { select, Store, StoreModule } from '@ngrx/store';

@Component({
  selector: 'fl-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  imports: [NgIf, StoreModule]
})
export class LogoComponent implements OnInit {
  @Input() tenant: Tenant;
  appLogo: string;

  constructor(private tenantStore: Store<TenantState>) {}

  ngOnInit() {
    if (this.tenant) {
      this.appLogo = this.tenant.tenant_setting?.app_logo;
    } else {
      this.getCurrentTenantLogo();
    }
  }

  getCurrentTenantLogo() {
    this.tenantStore.pipe(select(getCurrentTenant)).subscribe(t => {
      if (t !== 'loading' && t !== null) {
        this.appLogo = t?.tenant_setting?.app_logo;
        this.tenant = t;
      }
    });
  }
}
