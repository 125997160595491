import { Component, inject, OnInit } from '@angular/core';
import { DocumentCategory } from '@flink-legacy/core/declarations/document-category.interface';
import { Fetchable } from '@flink-legacy/core/declarations/fetchable.interface';
import { Observable } from 'rxjs';
import { SelectOption } from '@flink-legacy/shared/components/forms/select/select.component';
import { ModalAbstractComponent } from '@bling-fe/shared/modals/base-modal';
import { DocumentCategoryService } from '@flink-legacy/shared/services/document-category.service';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SelectModalBaseComponent } from '../select-modal-base/select-modal-base.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'fl-select-document-category-modal',
  templateUrl: './select-document-category-modal.component.html',
  styleUrls: ['./select-document-category-modal.component.scss'],
  imports: [
    IonicModule,
    TranslateModule,
    SelectModalBaseComponent,
    NgIf,
    NgFor,
    AsyncPipe
  ]
})
export class SelectDocumentCategoryModalComponent
  extends ModalAbstractComponent<
    { value: number; groupId: string },
    SelectOption<number>
  >
  implements OnInit
{
  private documentCategoryService = inject(DocumentCategoryService);
  documentCategories$: Observable<Fetchable<DocumentCategory[]>> =
    this.documentCategoryService.data$;

  ngOnInit() {
    const params = this.props.groupId
      ? { origin_id: Number(this.props.groupId), origin_type: 'Group' }
      : {};

    this.documentCategoryService.fetch(params);
  }
}
