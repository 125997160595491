import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newLineToBr',
  standalone: false
})
export class NewLineToBrPipe implements PipeTransform {
  transform(value: string): string {
    const parts = value.split(/\n/g);
    const result = parts.filter(e => e);
    return result.join('<br/>');
  }
}
