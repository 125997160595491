import { HttpClient } from '@angular/common/http';
import { Directive, Injector } from '@angular/core';
import { PaginatedResponse } from '@flink-legacy/core/declarations/paginated.interface';
import { getCurrentTenant } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { environment } from '@bling-fe/shared/env';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

/**
 * Use this in inherited services for defining params/response types.
 *
 * This utility type enables to override some keys of the base RepositoryParams definition.
 *
 * First parameter = base *Detail entity type, which is by default returned from findById, update & create endpoints
 * Second parameter = type overrides, e.g. { all: [] } for endpoints where #index always return empty array.
 */
export type DefineRepositoryParams<
  BASE_ENTITY,
  OVERRIDES extends Partial<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<keyof RepositoryParams<BASE_ENTITY>, any>
  > = {}
> = Omit<RepositoryParams<BASE_ENTITY>, keyof OVERRIDES> & OVERRIDES;

/**
 * Definition of all generic types used in abstract repository.
 *
 * Should not be needed to use this type in services/repositories, try using
 * DefineRepositoryParams helper instead
 */
export type RepositoryParams<
  T,
  ALL = PaginatedResponse<T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  QUERY_PARAMS extends Record<string, unknown> = Record<string, any>,
  CREATE = Partial<T>,
  CREATE_RESPONSE = T,
  UPDATE_PARAMS extends Identifiable = Partial<CREATE> & Identifiable,
  UPDATE = T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  DELETE_PARAMS extends Record<string, unknown> = Record<string, any>,
  DELETE_RESPONSE = {}
> = {
  item: T;
  all: ALL;
  queryParams: QUERY_PARAMS;
  createParams: CREATE;
  createResponse: CREATE_RESPONSE;
  updateParams: UPDATE_PARAMS;
  updateResponse: UPDATE;
  deleteParams: DELETE_PARAMS;
  deleteResponse: DELETE_RESPONSE;
};

export interface Identifiable {
  id?: number;
}

/**
 * Repository providing basic requests methods that corresponds to Rails index/show/update/create/delete actions
 *
 * @class RepositoryAbstract
 * @template T Type definitions for all the endpoints, use DefineRepositoryParams<Entity, {...overrides...}>
 * @template A do not use
 * @template B do not use
 * @template C do not use
 * @template D do not use
 * @template E do not use
 * @template F do not use
 * @template G do not use
 * @template H do not use
 * @template I do not use
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class RepositoryAbstract<
  T extends RepositoryParams<A, B, C, D, E, F, G, H, I>,
  // A-X definitions are "hacky" way to make TS satisfied
  //    T is source of truth for all the types that derived repository wants to work with.
  //    This is the way how to use those values
  A = T['item'],
  B = T['all'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  C extends Record<string, any> = T['queryParams'],
  D = T['createParams'],
  E = T['createResponse'],
  F extends Identifiable = T['updateParams'],
  G = T['updateResponse'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  H extends Record<string, any> = T['deleteParams'],
  I = T['deleteResponse']
> {
  protected RESOURCE_URL: string;
  protected TENANT_API_URL: string;
  protected http = this.injector.get<HttpClient>(HttpClient);
  private store = this.injector.get<Store>(Store);
  protected constructor(
    private endpoint: string,
    private injector: Injector
  ) {
    this.store
      .select(getCurrentTenant)
      .pipe()
      .subscribe(t => {
        if (t !== 'loading' && t !== null) {
          this.TENANT_API_URL = `${t.base_url}/api/v1/`;
          this.RESOURCE_URL = `${t.base_url}/api/v1/${endpoint}`;
        } else {
          this.RESOURCE_URL = `${environment.apiDomain}${endpoint}`;
          this.TENANT_API_URL = `${environment.apiDomain}`;
        }
      });
  }

  findAll(
    params: T['queryParams'] = {} as T['queryParams']
  ): Observable<T['all']> {
    return this.http.get<T['all']>(this.RESOURCE_URL, { params: params });
  }

  findById(id: number, params?): Observable<T['item']> {
    return this.http.get<T['item']>(`${this.RESOURCE_URL}/${id}`, { params });
  }

  create(entity: T['createParams']): Observable<T['createResponse']> {
    return this.http.post<T['createResponse']>(this.RESOURCE_URL, entity);
  }

  update(entity: T['updateParams']): Observable<T['updateResponse']> {
    return this.http.patch<T['updateResponse']>(
      `${this.RESOURCE_URL}/${entity.id}`,
      entity
    );
  }

  delete(
    id: number,
    params?: T['deleteParams']
  ): Observable<T['deleteResponse']> {
    return this.http.delete<T['deleteResponse']>(`${this.RESOURCE_URL}/${id}`, {
      params
    });
  }
}
