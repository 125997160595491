import { Injectable } from '@angular/core';
import {
  DocumentRepository,
  DocumentRepositoryParams
} from '../repositories/document.repository';
import { PaginatedAbstractService } from './paginated.abstract.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService extends PaginatedAbstractService<DocumentRepositoryParams> {
  constructor(documentRepository: DocumentRepository) {
    super(documentRepository);
  }
}
