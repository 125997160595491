import { Injectable } from '@angular/core';
import { Fetchable } from '@flink-legacy/core/declarations/fetchable.interface';

import { BehaviorSubject, Observable, tap } from 'rxjs';
import {
  Identifiable,
  RepositoryAbstract,
  RepositoryParams
} from '../repositories/repository.abstract';

/**
 * Abstract service for non-paginated repositories
 *
 * @template T provide *RepositoryParams from corresponding repository
 * @template A do not use
 * @template B do not use
 * @template C do not use
 * @template D do not use
 * @template E do not use
 * @template F do not use
 * @template G do not use
 * @template H do not use
 * @template I do not use
 */
@Injectable({
  providedIn: 'root'
})
export class BasicAbstractService<
  T extends RepositoryParams<A, B, C, D, E, F, G, H, I>,
  // A-X generic params are hack to provide something to RepositoryParams.
  // All are derived from T, which is source of truth
  A = T['item'],
  B extends A[] = T['all'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  C extends Record<string, any> = T['queryParams'],
  D = T['createParams'],
  E extends T['item'] = T['createResponse'],
  F extends Identifiable = T['updateParams'],
  G extends T['item'] = T['updateResponse'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  H extends Record<string, any> = T['deleteParams'],
  I = T['deleteResponse']
> {
  protected data: BasicBehaviorSubject<T['item']> = new BasicBehaviorSubject({
    state: 'loading',
    data: null
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected params = new BehaviorSubject<T['queryParams']>({} as any);

  get data$(): Observable<Fetchable<T['item'][]>> {
    return this.data.asObservable();
  }

  get params$(): Observable<T['queryParams']> {
    return this.params.asObservable();
  }

  constructor(protected repository: RepositoryAbstract<T>) {}

  fetch(params?: T['queryParams']) {
    this.data.next({
      state: 'loading',
      data: []
    });
    this.params.next(params);
    this.repository.findAll(this.params.value).subscribe({
      next: res => {
        this.data.next({
          state: 'ready',
          data: res
        });
      },
      error: err => this.setErrorState(err)
    });
  }

  refetch() {
    this.data.next({
      state: 'loading'
    });
    this.repository.findAll(this.params.value).subscribe({
      next: res => {
        this.data.next({
          state: 'ready',
          data: res
        });
      },
      error: err => this.setErrorState(err)
    });
  }

  create(entity: T['createParams']): Observable<T['createResponse']> {
    return this.repository.create(entity).pipe(
      tap(newItem => {
        if (this.data) {
          this.data.emitUpdateItems(items => [newItem, ...items]);
        }
      })
    );
  }

  update(entity: T['updateParams']): Observable<T['updateResponse']> {
    return this.repository.update(entity).pipe(
      tap(updatedItem => {
        this.data.replaceItem(entity.id, updatedItem);
      })
    );
  }

  delete(
    id: number,
    params?: T['deleteParams']
  ): Observable<T['deleteResponse']> {
    return this.repository.delete(id, params).pipe(
      tap(() => {
        this.data.emitDeleteItem(id);
      })
    );
  }

  private setErrorState(err) {
    // set page back to previos value so we can retry with fetchNextPage
    this.params.next({
      ...this.params.value
    });
    this.data.next({
      data: this.data.value.data,
      state: 'error',
      error: err
    });
    throw err;
  }
}

export class BasicBehaviorSubject<
  T extends Identifiable
> extends BehaviorSubject<Fetchable<T[]>> {
  /**
   * Deletes item at given index
   */
  public emitDeleteItem(itemToDelete: number | ((it: T) => boolean)) {
    const selector: (it: T) => boolean =
      typeof itemToDelete === 'number'
        ? it => it.id === itemToDelete
        : itemToDelete;
    const current = this.value.data ? this.value.data : [];
    const i = current.findIndex(it => selector(it));
    if (i !== -1) {
      const val = [...current];
      val.splice(i, 1);
      this.next({
        ...this.value,
        data: val
      });
    }
  }

  /**
   * Updates item inside items
   *
   *  we have: data: [{id: 1, val: "a"}, {id: 2, val: "b"}]
   *
   * Usage:
   *
   * 1. replace with object
   * data.replaceItem(2, {id: 2, val: "c"}) ==> data: [{id: 1, val: "a"}, {id: 2, val: "c"}]
   *
   * 2. use existing item value
   * data.replaceItem(2, (data) => {id: 2, val: `${data.val}c`}) ==> data: [{id: 1, val: "a"}, {id: 2, val: "ac"}]
   *
   */
  public replaceItem(id: number, replaceWith: T | ((original: T) => T)) {
    const current = this.value.data ? this.value.data : [];
    const i = current.findIndex(it => it.id === id);
    if (i !== -1) {
      const val = [...current];
      val.splice(
        i,
        1,
        typeof replaceWith === 'function'
          ? replaceWith(current[i])
          : replaceWith
      );
      this.next({
        ...this.value,
        data: val
      });
    }
  }

  /**
   * Modify data array easily
   *
   * we have: data: [{id: 1, val: "a"}, {id: 2, val: "b"}]
   *
   * Usage:
   *
   * data.emitUpdateItems((data) => {
   *    data === [{id: 1, val: "a"}, {id: 2, val: "b"}]
   *    return [{id: 0, val: "xx"}, ...data.map(it => {...it, val: 'x'})]
   * })
   * ==> data: [{id: 0, val: "xx"}, {id: 1, val: "x"}, {id: 2, val: "x"}]
   */

  public emitUpdateItems(updater: (data: T[]) => T[]) {
    const current = [...this.value.data];
    this.next({
      ...this.value,
      data: updater(current)
    });
  }
}
