import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutState } from '@flink-legacy/core/states/layout-state/layout.reducers';
import { getPageTransitionAnimated } from '@flink-legacy/core/states/layout-state/layout.selectors';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'fl-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
  standalone: false
})
export class BasicLayoutComponent implements OnInit {
  @HostBinding('style.--background') pageBG;
  pageTransitionAnimated$: Observable<boolean>;

  constructor(
    private store: Store<LayoutState>,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.pageTransitionAnimated$ = this.store.select(getPageTransitionAnimated);
    this.route.data.pipe(map(data => data?.['pageBG'])).subscribe(pageBG => {
      if (pageBG) {
        this.pageBG = pageBG;
      }
    });
  }
}
