import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from './translation-loader';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, Store]
      }
    })
  ],
  exports: [TranslateModule]
})
export class TranslationModule {}
