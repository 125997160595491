import { inject, Injectable } from '@angular/core';
import { ActionCableChannel } from '@flink-legacy/core/declarations/action_cable.interface';

import { filter, Observable } from 'rxjs';
import {
  ActionCableRepository,
  connected
} from '../repositories/action-cable.repository';
import { MessageNotifications } from '@flink-legacy/core/declarations/message-notifications.interface';

@Injectable({
  providedIn: 'root'
})
export class MessageNotificationService {
  private actionCableRepository = inject(ActionCableRepository);

  messageNotificationWSUpdates(): Observable<MessageNotifications> {
    return this.actionCableRepository
      .subscribeToChannel(ActionCableChannel.NotificationsChannel, {})
      .pipe(filter((x): x is MessageNotifications => x !== connected));
  }
}
