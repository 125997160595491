import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { getCurrentTenant } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { TenantState } from '@flink-legacy/core/states/tenant-state/tenant.state';
import { marker as _ } from '@bling-fe/shared/utils';
import { IonMenu } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import {
  getCommunityNotificationCount,
  getServicesNotificationCount
} from '@flink-legacy/core/states/message-notifications-state/message-notifications.selectors';
import { Observable } from 'rxjs';

export interface MenuSection {
  label: string;
  items: MenuItem[];
}

export interface MenuItem {
  label: string;
  url: string;
  icon: string;
  class?: string;
  showArrowIcon?: boolean;
  separator?: boolean;
  notificationCount$?: Observable<number>;
}

@Component({
  selector: 'fl-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  standalone: false
})
export class MainMenuComponent {
  @ViewChild(IonMenu) menu: IonMenu;

  communityNotifications$ = this.store.select(getCommunityNotificationCount);
  serviceNotifications$ = this.store.select(getServicesNotificationCount);

  desktopMenuItems: MenuItem[] = [
    {
      label: _('PAGES.TABS.PINNWAND'),
      url: '/home',
      icon: 'push-pin'
    },
    {
      label: _('PAGES.TABS.CALENDAR'),
      url: '/calendar',
      icon: 'calendar'
    },
    {
      label: _('PAGES.TABS.COMMUNITY'),
      url: '/community',
      icon: '3-user',
      notificationCount$: this.communityNotifications$
    },
    {
      label: _('PAGES.TABS.MY_TENENT'),
      url: '/my-tenant',
      icon: 'suitcase-simple',
      notificationCount$: this.serviceNotifications$
    },
    {
      label: _('PAGES.TABS.PROFILE'),
      url: '/my-profile',
      icon: 'profile'
    }
  ];

  mobileMenuItems: MenuSection[] = [
    { label: '', items: [...this.desktopMenuItems] }
  ];

  currentTenant$ = this.store.pipe(select(getCurrentTenant));

  constructor(
    private router: Router,
    private store: Store<TenantState>
  ) {}

  navigateUrl(url: string) {
    this.router.navigateByUrl(url);
  }
}
