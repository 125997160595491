import { Injectable } from '@angular/core';
import { PaginatedAbstractService } from './paginated.abstract.service';
import {
  UserRepository,
  UserRepositoryParams
} from '../repositories/user.repository';

@Injectable({
  providedIn: 'root'
})
export class UserService extends PaginatedAbstractService<UserRepositoryParams> {
  constructor(userRepository: UserRepository) {
    super(userRepository);
  }
}
