import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import {
  AuthenticatedGuard,
  authenticatedGuard
} from '@flink-legacy/shared/guards/authenticated.guard';
import { RedirectIfLoggedIn } from '@flink-legacy/shared/guards/redirect-if-logged-in.guard';
import { RedirectIfTermsAccepted } from '@flink-legacy/shared/guards/redirect-if-terms-accepted.guard';
import {
  TermsAcceptedGuard,
  termsAcceptedGuard
} from '@flink-legacy/shared/guards/terms-accepted.guard';
import { ReservationsEnabledGuard } from '@flink-legacy/shared/guards/reservations-enabled.guard';
import {
  TenantSelectedGuard,
  tenantSelectedGuard
} from '@flink-legacy/shared/guards/tenant-selected.guard';
import { CanSelectTenantGuard } from '@flink-legacy/shared/guards/can-select-tenant.guard';
import { instantMessagingEnabledGuard } from '@flink-legacy/shared/guards/instant-messaging-enabled.guard';

import { AppLayoutComponent } from '@flink-legacy/core/layout/app-layout/app-layout.component';
import { BasicLayoutComponent } from '@flink-legacy/core/layout/basic-layout/basic-layout.component';
import { ResetPasswordGuard } from '@flink-legacy/shared/guards/reset-password.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: 'home',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/home/home.module').then(
            m => m.HomePageModule
          )
      },
      {
        path: 'community',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/community/community.module').then(
            m => m.CommunityPageModule
          )
      },
      {
        path: 'my-profile',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/my-profile/my-profile.module').then(
            m => m.MyProfilePageModule
          )
      },
      {
        path: 'my-profile/delete-account',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/my-profile-delete-account/my-profile-delete-account.module'
          ).then(m => m.MyProfileDeleteAccountPageModule)
      },
      {
        path: 'calendar',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/calendar/calendar.module').then(
            m => m.CalendarPageModule
          )
      },
      {
        path: 'my-tenant',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import('@flink-legacy/pages//my-tenant/my-tenant.module').then(
            m => m.MyTenantPageModule
          )
      },
      {
        path: 'new',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/add-new/add-new.module').then(
            m => m.AddNewPageModule
          )
      },
      {
        path: 'edit',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/edit/edit.module').then(m => m.EditModule)
      },
      {
        path: 'under-construction',
        loadChildren: () =>
          import(
            '@flink-legacy/pages/under-construction/under-construction.module'
          ).then(m => m.UnderConstructionPageModule)
      },
      {
        path: 'post/:id',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/post-detail/post-detail.module').then(
            m => m.PostDetailPageModule
          )
      },
      {
        path: 'event/:id',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/event-detail/event-detail.module').then(
            m => m.EventDetailPageModule
          )
      },
      {
        path: 'ticket/:id',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/repair-message-detail/repair-message-detail.module'
          ).then(m => m.RepairMessageDetailPageModule)
      },
      {
        path: 'hourly-reservable/:type/:id',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          ReservationsEnabledGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/hourly-reservable-detail/hourly-reservable-detail.module'
          ).then(m => m.HourlyReservableDetailPageModule)
      },
      {
        path: 'hourly-reservation/:type/:id',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          ReservationsEnabledGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/hourly-reservation-detail/hourly-reservation-detail.module'
          ).then(m => m.HourlyReservationDetailPageModule)
      },
      {
        path: 'daily-reservable/:type/:id',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          ReservationsEnabledGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/daily-reservable-detail/daily-reservable-detail.module'
          ).then(m => m.DailyReservableDetailPageModule)
      },
      {
        path: 'daily-reservation/:type/:id',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          ReservationsEnabledGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/daily-reservation-detail/daily-reservation-detail.module'
          ).then(m => m.DailyReservationDetailPageModule)
      },
      {
        path: 'profile/:id',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/profile/profile.module').then(
            m => m.ProfilePageModule
          )
      },
      {
        path: 'instant-messaging/:id',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          TermsAcceptedGuard,
          instantMessagingEnabledGuard
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/instant-messaging/instant-messaging.module'
          ).then(m => m.InstantMessagingPageModule)
      },
      {
        path: 'group/:group_id',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/group-detail/group-detail.module').then(
            m => m.GroupDetailPageModule
          )
      },
      {
        path: 'changelog',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/changelog/changelog.module').then(
            m => m.ChangelogPageModule
          )
      },
      {
        path: 'help',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/help/help.module').then(
            m => m.HelpPageModule
          )
      },
      {
        path: 'privacy-policy',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          TermsAcceptedGuard
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/privacy-policy/privacy-policy.module'
          ).then(m => m.PrivacyPolicyPageModule)
      },
      {
        path: 'terms-of-use',
        loadChildren: () =>
          import('@flink-legacy/pages/terms-of-use/terms-of-use.module').then(
            m => m.TermsOfUsePageModule
          )
      },
      {
        path: 'legal-notice',
        loadChildren: () =>
          import('@flink-legacy/pages/legal-notice/legal-notice.module').then(
            m => m.LegalNoticePageModule
          )
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('@flink-legacy/pages/contact/contact.module').then(
            m => m.ContactPageModule
          )
      },
      {
        path: 'oauth-verify-is-authenticated',
        canActivate: [
          authenticatedGuard,
          tenantSelectedGuard,
          termsAcceptedGuard
        ],
        loadComponent: () =>
          import(
            '@flink-legacy/pages/oauth-verify-is-authenticated/oauth-verify-is-authenticated.page'
          ).then(m => m.OauthVerifyIsAuthenticatedPage)
      },
      {
        path: 'not-found',
        loadComponent: () =>
          import('@bling-fe/shared/feature-not-found').then(
            m => m.SharedFeatureNotFoundComponent
          )
      }
    ]
  },
  {
    path: 'login',
    component: BasicLayoutComponent,
    canActivateChild: [TenantSelectedGuard, RedirectIfLoggedIn],
    loadChildren: () =>
      import('@flink-legacy/pages/login/login.module').then(
        m => m.LoginPageModule
      )
  },
  {
    path: 'reset-password',
    component: BasicLayoutComponent,
    canActivateChild: [TenantSelectedGuard, ResetPasswordGuard],
    loadChildren: () =>
      import('@flink-legacy/pages/reset-password/reset-password.module').then(
        m => m.ResetPasswordPageModule
      )
  },
  {
    path: 'email-confirmation',
    component: BasicLayoutComponent,
    canActivateChild: [TenantSelectedGuard],
    loadChildren: () =>
      import(
        '@flink-legacy/pages/email-confirmation/email-confirmation.module'
      ).then(m => m.EmailConfirmationPageModule)
  },
  {
    path: 'onboarding',
    component: BasicLayoutComponent,
    canActivateChild: [CanSelectTenantGuard],
    data: { pageBG: 'var(--ion-color-light)' },
    loadChildren: () =>
      import('@flink-legacy/pages/onboarding/onboarding.module').then(
        m => m.OnboardingPageModule
      )
  },
  {
    path: 'terms-and-conditions',
    component: BasicLayoutComponent,
    canActivateChild: [
      TenantSelectedGuard,
      AuthenticatedGuard,
      RedirectIfTermsAccepted
    ],
    loadChildren: () =>
      import('@flink-legacy/pages/accept-terms/accept-terms.module').then(
        m => m.AcceptTermsPageModule
      )
  },
  {
    path: 'terms-and-conditions-public',
    loadChildren: () =>
      import(
        '@flink-legacy/pages/terms-and-conditions/terms-and-conditions.module'
      ).then(m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'privacy-policy-public',
    component: BasicLayoutComponent,
    canActivateChild: [],
    loadChildren: () =>
      import('@flink-legacy/pages/privacy-policy/privacy-policy.module').then(
        m => m.PrivacyPolicyPageModule
      )
  },
  {
    path: 'payment',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('@flink-legacy/payment-status/payment-status.module').then(
        m => m.PaymentSuccessfulPageModule
      )
  },
  // default path is home
  {
    path: '**',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@bling-fe/shared/feature-not-found').then(
            m => m.SharedFeatureNotFoundComponent
          )
      }
    ]
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
