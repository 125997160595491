import { UserFilterParams } from '@flink-legacy/core/declarations/user.interface';

export const INITIAL_USERS_FILTER_STATE: UserFilterParams = {
  online_only: false
};

export interface UserFilterState {
  usersFilterState: UserFilterParams;
}

export const initialUserFilterState: UserFilterState = {
  usersFilterState: INITIAL_USERS_FILTER_STATE
};
