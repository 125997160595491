import { Injectable, Injector } from '@angular/core';
import { ArticleDetail } from '@flink-legacy/core/declarations/article-detail.interface';
import { Like } from '@flink-legacy/core/declarations/like.interface';
import { PaginatedResponse } from '@flink-legacy/core/declarations/paginated.interface';
import {
  Article,
  ArticleDeleteParams,
  ArticleFilterParams,
  ArticleParams
} from '@flink-legacy/interfaces/article';
import {
  DefineRepositoryParams,
  Identifiable,
  RepositoryAbstract
} from '@flink-legacy/shared/repositories/repository.abstract';
import { Observable } from 'rxjs';

export interface ArticleBookmarkResponse {
  message: string;
  bookmarked: boolean;
}

export type ArticleRepositoryParams = DefineRepositoryParams<
  ArticleDetail,
  {
    queryParams: ArticleFilterParams;
    createParams: ArticleParams;
    updateParams: ArticleParams & Identifiable;
    deleteParams: ArticleDeleteParams;
    all: PaginatedResponse<Article>;
  }
>;

@Injectable({
  providedIn: 'root'
})
export class ArticleRepository extends RepositoryAbstract<ArticleRepositoryParams> {
  constructor(injector: Injector) {
    super(`/articles`, injector);
  }

  like(id: number): Observable<Like> {
    return this.http.post<Like>(`${this.RESOURCE_URL}/${id}/likes`, null);
  }

  vote(id: number, option: number): Observable<ArticleDetail> {
    return this.http.post<ArticleDetail>(
      `${this.RESOURCE_URL}/${id}/vote/${option}`,
      {}
    );
  }

  pin(id: number): Observable<ArticleDetail> {
    return this.http.get<ArticleDetail>(
      `${this.RESOURCE_URL}/${id}/toggle_sticky`
    );
  }
}
