import { Comment } from './../../../../core/declarations/comment.interface';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from '../../../../core/declarations/user.interface';
import { map } from 'rxjs/operators';
import { marker as _ } from '@bling-fe/shared/utils';
import { getCurrentUser } from '@flink-legacy/core/states/user-state/user.selectors';
import { getEditingComment } from '@flink-legacy/core/states/comment-state/comment.selectors';
import { CommentState } from '@flink-legacy/core/states/comment-state/comment.state';
import { UserState } from '@flink-legacy/core/states/user-state/user.reducers';
import { SetEditingComment } from '@flink-legacy/core/states/comment-state/comment.actions';
import { CommentRepository } from '@flink-legacy/shared/repositories/comment.repository';
import { PostService } from '@flink-legacy/shared/services/post.service';
import { ReportService } from '@flink-legacy/shared/services/report.service';
import { ModalService } from '@bling-fe/shared/modals/base-modal';
import {
  OptionsModalAction,
  SharedModalsUiOptionsModalComponent
} from '@flink-legacy/modals/shared-modals-ui-options-modal/shared-modals-ui-options-modal.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@flink-legacy/shared/pipes/pipes.module';
import { IonicModule } from '@ionic/angular';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { AvatarComponent } from '../../avatar/avatar.component';

@Component({
  selector: 'fl-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss'],
  imports: [
    IonicModule,
    NgIf,
    AsyncPipe,
    TranslateModule,
    PipesModule,
    SvgIconComponent,
    AvatarComponent
  ]
})
export class CommentItemComponent implements OnInit {
  @Input() comment: Comment;

  loggedUser$: Observable<User>;
  editing$: Observable<boolean>;

  constructor(
    private router: Router,
    private userStore: Store<UserState>,
    private commentStore: Store<CommentState>,
    private modalService: ModalService,
    private commentRepository: CommentRepository,
    private postService: PostService,
    private reportService: ReportService
  ) {}

  ngOnInit() {
    this.loggedUser$ = this.userStore.pipe(select(getCurrentUser));
    this.editing$ = this.commentStore.pipe(
      select(getEditingComment),
      map(x => x?.id === this.comment.id)
    );
  }

  async showMoreOptions(logedUser: User) {
    // admin & superadmin have to give a reason to delete a comment
    const isCommentOwner = logedUser.id === this.comment.user.id;
    const isAdmin = logedUser.super_admin || logedUser.admin;
    const actionsEnabled: OptionsModalAction[] = [];

    if (isCommentOwner) {
      actionsEnabled.push('edit', 'delete');
    } else {
      actionsEnabled.push('flag', 'block');
      if (isAdmin) {
        actionsEnabled.push('deleteWithReason');
      }
    }

    const res = await this.modalService.presentAndGetResult(
      SharedModalsUiOptionsModalComponent,
      {
        title: _('MODALS.OPTIONS.COMMENT.TITLE'),
        deleteModalProps: {
          title: _('MODALS.OPTIONS.COMMENT.DELETE_TITLE'),
          description: _('MODALS.OPTIONS.COMMENT.DELETE_DESCRIPTION')
        },
        flagModalProps: {
          title: _('MODALS.OPTIONS.COMMENT.REPORT.TITLE'),
          confirmLabel: _('MODALS.OPTIONS.COMMENT.REPORT.REPORT')
        },
        blockModalProps: {
          title: _('MODALS.OPTIONS.COMMENT.BLOCK.TITLE'),
          confirmLabel: _('MODALS.OPTIONS.COMMENT.BLOCK.BLOCK')
        },
        actionsEnabled
      }
    );

    if (!res) {
      return;
    }

    switch (res.action) {
      case 'edit':
        this.commentStore.dispatch(new SetEditingComment(this.comment));
        break;
      case 'delete':
        this.sendDeleteRequest(this.comment);
        break;
      case 'deleteWithReason':
        this.sendDeleteRequest(this.comment, res.reason_for_action);
        break;
      case 'flag':
        this.reportService.handleReport({
          itemId: this.comment.id,
          itemType: 'Comment',
          reportType: 'flag',
          reason: res.reason_for_action
        });
        break;
      case 'block':
        this.reportService.handleReport({
          itemId: this.comment.id,
          itemType: 'Comment',
          reportType: 'block',
          reason: res.reason_for_action
        });
        break;
    }
  }

  sendDeleteRequest(comment, modalMessage?) {
    this.commentRepository
      .deleteComment(comment, { reason_for_deletion: modalMessage })
      .subscribe(() => {
        this.comment.hidden = true;
        this.postService.commentDeleted(comment.article_id);
        // this.comment = { ...this.comment, hidden: true };
      });
  }

  showProfile(id: number): void {
    this.router.navigateByUrl(`/profile/${id}`);
  }
}
