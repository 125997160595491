import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';

import { CREATED_DATE_FORMAT } from '@flink-legacy/core/declarations/constants';

@Pipe({
  name: 'elapsedTime',
  standalone: false
})
export class ElapsedTimePipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private datePipe: DatePipe
  ) {}

  // creating time
  // if time is not over two days, it will be elapsed time e.g. '1h ago',
  // otherwise, it will be general time, e.g. '10. Oktober 2021 13:10'
  transform(created_at: string): string | null {
    if (created_at) {
      const now = dayjs();
      const dayDiff = now.diff(created_at, 'day');
      const hourDiff = now.diff(created_at, 'hour');
      const minuteDiff = now.diff(created_at, 'minute');

      if (minuteDiff < 1)
        return this.translate.instant('COMMON.LABELS.JUST_NOW');

      if (dayDiff < 1) {
        if (hourDiff < 1) {
          return this.translate.instant('COMMON.LABELS.BEFORE_TIME', {
            time: `${minuteDiff} ${this.translate.instant(
              'COMMON.UNITS.MINUTES'
            )}`
          });
        } else {
          return this.translate.instant('COMMON.LABELS.BEFORE_TIME', {
            time: `${hourDiff} ${this.translate.instant('COMMON.UNITS.HOURS')}`
          });
        }
      } else {
        return this.datePipe.transform(created_at, CREATED_DATE_FORMAT);
      }
    }
    return created_at;
  }
}
