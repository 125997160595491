<ion-header
  [class.ion-no-border]="!showHeaderBorder"
  [class.mb-3]="showHeaderBorder"
  [attr.aria-label]="title"
>
  <ion-toolbar>
    <ion-buttons
      slot="start"
      *ngIf="showClose"
    >
      <ion-button
        (click)="closeClick.emit()"
        class="close-btn ion-no-margin"
        [title]="'COMMON.LABELS.CLOSE' | translate"
        [attr.aria-label]="'COMMON.LABELS.CLOSE' | translate"
        size="small"
      >
        <svg-icon
          class="color--dark"
          key="close"
          size="xl"
        ></svg-icon>
      </ion-button>
    </ion-buttons>
    <ion-title
      class="text--base text--medium"
      id="select-modal-title"
      role="heading"
      aria-level="1"
    >
      {{ title }}
    </ion-title>
    <ng-content select="ion-buttons"></ng-content>
  </ion-toolbar>
  <ion-toolbar *ngIf="showSearchBar">
    <ion-input
      class="search-input"
      [placeholder]="searchPlaceholder"
      (ionInput)="search.emit($any($event.target).value)"
    >
      <ion-icon
        slot="start"
        src="/assets/icon/search.svg"
      ></ion-icon>
    </ion-input>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ng-content></ng-content>
</ion-content>
