import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule
} from '@angular/core';
import { BasicDocument } from '@flink-legacy/core/declarations/document.interface';
import { FileUpload } from '@flink-legacy/core/declarations/file.interface';
import { mimeTypes } from '../forms/file/file.component';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@NgModule({})
export class DocumentImageComponentIconsModule {}

@Component({
  selector: 'fl-document-image',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <svg-icon
      *ngIf="fileType === 'doc'"
      key="file-doc"
      class="success"
      [title]="'COMMON.DOCUMENTS.WORD' | translate"
    ></svg-icon>
    <svg-icon
      *ngIf="fileType === 'pdf'"
      key="file-pdf"
      class="danger"
      [title]="'COMMON.DOCUMENTS.PDF' | translate"
    ></svg-icon>
    <svg-icon
      *ngIf="fileType === 'unknown'"
      key="file-unknown"
      class="warning"
      [title]="'COMMON.DOCUMENTS.OTHER' | translate"
    ></svg-icon>
  `,
  styleUrls: ['./document-image.component.scss'],
  imports: [
    SvgIconComponent,
    TranslateModule,
    NgIf,
    DocumentImageComponentIconsModule
  ]
})
export class DocumentImageComponent {
  public fileType: 'doc' | 'pdf' | 'unknown';

  @Input() set document(doc: FileUpload | BasicDocument) {
    const type = 'content_type' in doc ? doc.content_type : doc.mimeType;

    const extension = Object.entries(mimeTypes).find(([k]) => k === type)?.[1];

    this.fileType =
      extension === 'doc' || extension === 'pdf' ? extension : 'unknown';
  }
}
