import { Injectable } from '@angular/core';
import {
  DocumentCategoryRepository,
  DocumentCategoryRepositoryParams
} from '../repositories/document-category.repository';
import { BasicAbstractService } from './basic.abstract.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentCategoryService extends BasicAbstractService<DocumentCategoryRepositoryParams> {
  constructor(documentCategoryRepository: DocumentCategoryRepository) {
    super(documentCategoryRepository);
  }
}
