<ion-item
  class="ion-align-items-start"
  lines="none"
>
  <fl-avatar
    [user]="comment?.user"
    [size]="40"
  ></fl-avatar>
  <div class="comment-container">
    <div
      class="comment-body d-flex flex-col"
      [class]="
        (editing$ | async) ? 'background--light-shade' : 'background--light'
      "
    >
      <div class="d-flex ion-align-items-start ion-justify-content-between">
        <span class="author text--xs text--bold">
          <ng-container *ngIf="comment?.user; else deletedUser">
            {{ comment.user.full_name }}
          </ng-container>
          <ng-template #deletedUser>
            {{ "COMMON.LABELS.DELETED_USER" | translate }}
          </ng-template>
        </span>
        <ng-container *ngIf="loggedUser$ | async as loggedUser">
          <div>
            <ion-button
              class="more-actions-btn"
              fill="clear"
              size="small"
              (click)="showMoreOptions(loggedUser)"
              [attr.aria-label]="'COMMON.LABELS.OPTIONS' | translate"
              [title]="'COMMON.LABELS.OPTIONS' | translate"
            >
              <svg-icon
                class="ion-color-medium'"
                slot="icon-only"
                key="dots-three"
                aria-hidden="true"
              ></svg-icon>
            </ion-button>
          </div>
        </ng-container>
      </div>
      <div
        class="content text--xs"
        [innerHTML]="
          comment.body | linky: { className: 'external-link' } | newLineToBr
        "
      ></div>
    </div>
    <div class="mt-2 ml-2 color--medium text--xs">
      <span>{{ comment.created_at | elapsedTime }}</span>
    </div>
  </div>
</ion-item>
