import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageNotificationService } from '@flink-legacy/shared/services/message-notification.service';
import { ActionCableRepository } from '@flink-legacy/shared/repositories/action-cable.repository';
import {
  MessageNotification,
  MessageNotifications,
  RepairMessageNotification
} from '@flink-legacy/core/declarations/message-notifications.interface';
import {
  catchError,
  debounceTime,
  EMPTY,
  map,
  switchMap,
  takeUntil
} from 'rxjs';
import {
  SetMessageNotifications,
  SubscribeToBENotifications,
  UnsubscribeFromBENotifications
} from './message-notifications.actions';

@Injectable()
export class MessageNotificationsEffects {
  loadMessageNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscribeToBENotifications),
      switchMap(() => {
        return this.messageNotificationService
          .messageNotificationWSUpdates()
          .pipe(
            this.actionCableRepository.defaultWsConnectionErrorHandler(),
            takeUntil(
              this.actions$.pipe(ofType(UnsubscribeFromBENotifications))
            ),
            debounceTime(500),
            map((newNotifications: MessageNotifications) => {
              const newNotificationsWithCount = {
                services: {
                  count: this.sumNotificationsByType(
                    newNotifications.repair_message_notifications
                  ),
                  notifications: newNotifications.repair_message_notifications
                },
                community: {
                  count: this.sumNotificationsByType(
                    newNotifications.conversation_notifications
                  ),
                  notifications: newNotifications.conversation_notifications
                }
              };
              return SetMessageNotifications({
                notifications: newNotificationsWithCount
              });
            }),
            catchError(() => EMPTY)
          );
      })
    )
  );

  private sumNotificationsByType(
    notifications: (RepairMessageNotification | MessageNotification)[]
  ): number {
    return notifications.reduce(
      (total, notification) => total + notification.notification_count,
      0
    );
  }

  constructor(
    private actions$: Actions,
    private messageNotificationService: MessageNotificationService,
    private actionCableRepository: ActionCableRepository
  ) {}
}
