import { Injectable } from '@angular/core';
import { Share } from '@capacitor/share';

@Injectable({
  providedIn: 'root'
})
export class ShareService {
  public static enabled = Share.canShare().then(res => res.value);
  constructor() {}

  async share({ title, text, url }: ShareData) {
    if (await ShareService.enabled) {
      return Share.share({
        title,
        text,
        url
      }).catch(e => {
        // e.name is Safari API, e.message was there before,
        // had no time to test if still works everywhere just with the `AbortError` catch
        if (e?.name !== 'AbortError' && !e?.message.includes('cancel')) {
          throw e;
        }
      });
    }
  }
}
