import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { filter, first, Observable } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@bling-fe/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordGuard {
  private router = inject(Router);
  private notification = inject(NotificationService);
  private translate = inject(TranslateService);

  canActivateChild(
    route: ActivatedRouteSnapshot,
    __: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      !!route.queryParamMap.get('invitation_token') ||
      !!route.queryParamMap.get('access-token')
    ) {
      return true;
    } else {
      this.translate
        .stream(_('COMMON.ERRORS.LINK_INVALID'))
        .pipe(
          // translations are not loaded when tried this.translate.instant
          filter(t => t !== 'COMMON.ERRORS.LINK_INVALID'),
          first()
        )
        .subscribe(invalid_link_error => {
          this.notification.error(this.translate.instant(invalid_link_error));
        });

      return this.router.parseUrl('/login');
    }
  }
}
