import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { IonDatetime, IonicModule, ToastController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import dayjs from 'dayjs';
import { DatetimeHighlight } from '@ionic/core';
import { difference } from 'lodash';
import { FormsModule } from '@angular/forms';

export interface DailyReservableEvent {
  start: string;
  end: string;
}

export interface DateRange {
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
}

const isSameOrAfter = (date1: dayjs.Dayjs, date2: dayjs.Dayjs): boolean =>
  date1.isAfter(date2) || date1.isSame(date2);

const isSameOrBefore = (date1: dayjs.Dayjs, date2: dayjs.Dayjs): boolean =>
  date1.isBefore(date2) || date1.isSame(date2);

@Component({
  selector: 'bl-data-range-selector',
  standalone: true,
  imports: [CommonModule, TranslateModule, IonicModule, FormsModule],
  templateUrl: './ui-data-range-selector.component.html',
  styleUrls: ['./ui-data-range-selector.component.scss']
})
export class UiDataRangeSelectorComponent implements OnInit {
  @Input() dailyReservableEvents: DailyReservableEvent[] = [];
  @Input() maxDuration = 0;
  @Input() minGapBetween = 0;
  @Input() reserveDaysInAdvance: number | undefined;
  @Input() currentUserSelection: string[] = [];

  @Output() valueChange = new EventEmitter<DateRange>();

  @ViewChild(IonDatetime) calendar: IonDatetime | undefined;

  minDate: string | undefined = undefined;
  maxDate: string | undefined = undefined;
  disabledConfirm = true;

  selectedDates: DatetimeHighlight[] = [];
  daysWithReservation: DatetimeHighlight[] = [];
  singleSideDates: { date: string; type: 'start' | 'end' }[] = [];

  get today() {
    return dayjs().format('YYYY-MM-DD');
  }

  get from() {
    return this.currentUserSelection[0];
  }

  get to() {
    return this.currentUserSelection[1];
  }

  get highlightedDates() {
    return [...this.daysWithReservation, ...this.selectedDates];
  }

  constructor(
    private toastController: ToastController,
    private i18n: TranslateService
  ) {}

  async ngOnInit() {
    if (this.reserveDaysInAdvance != undefined) {
      this.minDate = dayjs()
        .add(this.reserveDaysInAdvance, 'd')
        .format('YYYY-MM-DD');
      this.handleChoseDate();
      await this.handleDateChange();
    }
  }

  confirm() {
    this.valueChange.emit({
      from: dayjs(this.currentUserSelection[0]),
      to: dayjs(this.currentUserSelection[1])
    });
    this.calendar?.confirm(true);
  }

  async handleDateChange(event?: CustomEvent) {
    const selectedDates =
      (event?.detail.value as string[]) || this.currentUserSelection;
    if (selectedDates.length > 2) {
      if (
        this.isDateBetween(selectedDates[2], selectedDates[0], selectedDates[1])
      ) {
        this.currentUserSelection = [selectedDates[selectedDates.length - 1]];
      } else {
        this.currentUserSelection = [
          selectedDates[1],
          selectedDates[selectedDates.length - 1]
        ];
      }
      this.currentUserSelection = this.currentUserSelection.sort((a, b) =>
        dayjs(a).isBefore(dayjs(b)) ? -1 : 1
      );
    } else {
      const sorted = selectedDates.sort((a, b) =>
        dayjs(a).isBefore(dayjs(b)) ? -1 : 1
      );
      if (sorted.length === 2) {
        this.currentUserSelection = [sorted[0], sorted[1]];
      } else {
        this.currentUserSelection = sorted;
      }
    }
    if (this.maxDuration && this.currentUserSelection.length === 1) {
      this.maxDate = dayjs(this.currentUserSelection[0])
        .add(this.maxDuration, 'd')
        .format('YYYY-MM-DD');
      this.minDate = dayjs(this.currentUserSelection[0])
        .subtract(this.maxDuration, 'd')
        .format('YYYY-MM-DD');
      if (this.minDate < this.today) {
        this.minDate = this.today;
      }
    } else if (!this.currentUserSelection.length) {
      this.maxDate = undefined;
      if (this.reserveDaysInAdvance) {
        this.minDate = dayjs()
          .add(this.reserveDaysInAdvance, 'd')
          .format('YYYY-MM-DD');
      }
    }

    this.selectedDates = this.getHighlightedDates(
      this.currentUserSelection[0],
      this.currentUserSelection[1]
    );

    this.disabledConfirm = !this.validateSelectedDateRange({
      from: dayjs(this.currentUserSelection[0]),
      to: dayjs(this.currentUserSelection[1])
    });

    // this.calendar?.ionChange.emit();
  }

  getHighlightedDates = (startDate: string, endDate: string) => {
    const dates: DatetimeHighlight[] = [];
    if (!endDate) {
      return dates;
    }

    const start = dayjs(startDate);
    const end = dayjs(endDate);
    let current = start;
    while (current.isBefore(end)) {
      const backgroundColor = 'var(--ion-color-primary-light)';

      const existingDayConfig = this.daysWithReservation.find(
        dayConfig => dayConfig.date === current.format('YYYY-MM-DD')
      );
      if (existingDayConfig) {
        existingDayConfig.backgroundColor = 'var(--ion-color-warning)';
        existingDayConfig.textColor = 'var(--ion-color-contrast)';
      }

      dates.push({
        date: current.format('YYYY-MM-DD'),
        backgroundColor,

        textColor: 'var(--ion-color-contrast)'
      });
      current = current.add(1, 'day');
    }
    return dates;
  };

  isDateBetween = (date: string, start: string, end: string) => {
    return dayjs(date).isAfter(start) && dayjs(date).isBefore(end);
  };

  isDateAvailable = (date: string) => {
    return !this.dailyReservableEvents.some(
      event =>
        dayjs(date).isAfter(event.start) && dayjs(date).isBefore(event.end)
      /* ||
        dayjs(date).isSame(event.start) ||
        dayjs(date).isSame(event.end) */
    );
  };

  private handleChoseDate(): void {
    const sideDates: (string | Date)[] = [];
    let disabledDates: (string | Date)[] = [];
    let reservedStartDates: (string | Date)[] = [];
    let reservedEndDates: (string | Date)[] = [];

    this.dailyReservableEvents?.forEach((event: DailyReservableEvent) => {
      if (dayjs(event.start).isAfter(dayjs().subtract(1, 'day'))) {
        sideDates.push(event.start);
        reservedStartDates.push(event.start);
      }
      if (dayjs(event.end).isAfter(dayjs().subtract(1, 'day'))) {
        sideDates.push(event.end);
        reservedEndDates.push(event.end);
      }
      // get all reserved dates (start dates, end dates, middle days)
      disabledDates = [...disabledDates, ...this.getDaysBetweenDates(event)];
    });

    // side days which been chosen twice should be disabled
    const duplicateSideDates = sideDates.filter(
      (sideDate, index) => index !== sideDates.indexOf(sideDate)
    );

    disabledDates = [...disabledDates, ...duplicateSideDates];

    // remove duplicate side dates from start dates and end dates
    reservedStartDates = difference(reservedStartDates, duplicateSideDates);
    reservedEndDates = difference(reservedEndDates, duplicateSideDates);

    // all middle days should be disabled
    disabledDates.forEach(disabledDate =>
      this.daysWithReservation.push({
        date: dayjs(disabledDate).format('YYYY-MM-DD'),
        textColor: 'var(--ion-color-warning)'
      })
    );

    // and push them into singleSideDates
    reservedStartDates.forEach(date => {
      this.daysWithReservation.push({
        date: dayjs(date).format('YYYY-MM-DD'),
        textColor: 'var(--ion-color-warning)'
      });
      this.singleSideDates.push({
        date: dayjs(date).format('YYYY-MM-DD'),
        type: 'start'
      });
    });

    reservedEndDates.forEach(date => {
      this.daysWithReservation.push({
        date: dayjs(date).format('YYYY-MM-DD'),
        textColor: 'var(--ion-color-warning)'
      });
      this.singleSideDates.push({
        date: dayjs(date).format('YYYY-MM-DD'),
        type: 'end'
      });
    });
  }

  private getDaysBetweenDates(reservedDate: DailyReservableEvent) {
    const startDate = dayjs(reservedDate.start);
    const endDate = dayjs(reservedDate.end);
    let now = startDate.add(1, 'day');
    const dates = [];

    if (endDate.diff(startDate, 'day') > 1) {
      while (now.isBefore(endDate)) {
        if (now.isAfter(dayjs().subtract(1, 'day'))) {
          dates.push(now.format('MM/DD/YYYY'));
        }
        now = now.add(1, 'day');
      }
    }
    return dates;
  }

  private async showError(message: string) {
    await this.toastController
      .create({
        message,
        color: 'danger',
        position: 'bottom',
        duration: 4000
      })
      .then(toast => {
        toast.present();
      });
  }

  private validateSelectedDateRange(dateRange: DateRange): boolean {
    const errors: string[] = [];

    // have to have more than one day
    // if (dateRange.to.diff(dateRange.from, 'day') < 1) {
    //   errors.push(
    //     this.i18n.instant(
    //       'PAGES.ADD_NEW.DAILY_RESERVATION.ERROR.ONE_NIGHT_AT_LEAST'
    //     )
    //   );
    // }

    if (
      this.maxDuration > 0 &&
      dateRange.to.diff(dateRange.from, 'day') > this.maxDuration
    ) {
      errors.push(
        this.i18n.instant(
          'PAGES.ADD_NEW.DAILY_RESERVATION.ERROR.MAX_DURATION_EXCEEDED',
          { maxDuration: this.maxDuration }
        )
      );
    }

    // side date can not be middle date anymore
    // each side date just can be assigned to start date or end date once or less
    for (const singleSideDate of this.singleSideDates) {
      const date = dayjs(singleSideDate.date);
      if (
        isSameOrAfter(date, dateRange.from) &&
        isSameOrBefore(date, dateRange.to) &&
        ((!date.isSame(dateRange.to) && singleSideDate.type === 'start') ||
          (!date.isSame(dateRange.from) && singleSideDate.type === 'end'))
      ) {
        errors.push(
          this.i18n.instant(
            'PAGES.ADD_NEW.DAILY_RESERVATION.ERROR.INCLUDE_DISABLED_DATES'
          )
        );
        break;
      }
    }

    for (const error of errors) {
      this.showError(error);
    }
    return errors.length === 0;
  }
}
