import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { CommonModule } from '@angular/common';
import { TranslationModule } from '@flink-legacy/core/translation/translation.module';
import { SvgIconComponent } from '@ngneat/svg-icon';
import {
  CustomModalOptions,
  ModalAbstractComponent
} from '@bling-fe/shared/modals/base-modal';
import { RouterTestingModule } from '@angular/router/testing';
import { PdfViewerModule } from 'ng2-pdf-viewer';

export interface PdfModalProps {
  pdfUrl: string;
  title: string;
}

@Component({
  templateUrl: './pdf-modal.component.html',
  styleUrls: ['./pdf-modal.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    TranslationModule,
    SvgIconComponent,
    RouterTestingModule,
    PdfViewerModule
  ],
  providers: [
    // for storybook
    { provide: ModalAbstractComponent, useExisting: PdfModalComponent }
  ]
})
export class PdfModalComponent extends ModalAbstractComponent<
  PdfModalProps,
  void
> {
  public static override modalOptions: CustomModalOptions = {
    cssClass: 'pdf-modal'
  };
}
