import { ViewportRuler } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';
import {
  Breakpoint,
  BREAKPOINT,
  DESKTOP_BREAKPOINT
} from '@flink-legacy/core/declarations/constants';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { handleResize, setBreakpoint, setScreensize } from './layout.actions';

@Injectable()
export class LayoutEffects {
  handleResizeBreakpoint$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(handleResize),
      map(({ width }) => {
        // While bootstraping the app, passed width argument can be 0,
        // fallback to viewport ruler in that case
        const currentWidth = width || this.viewport.getViewportSize().width;

        let breakpoint: Breakpoint;
        switch (true) {
          case currentWidth >= BREAKPOINT.XL:
            breakpoint = 'xl';
            break;
          case currentWidth >= BREAKPOINT.LG:
            breakpoint = 'lg';
            break;
          case currentWidth >= BREAKPOINT.MD:
            breakpoint = 'md';
            break;
          case currentWidth >= BREAKPOINT.SM:
            breakpoint = 'sm';
            break;
          // Default breakpoint, set to null
          case currentWidth < BREAKPOINT.SM && currentWidth > BREAKPOINT.XS:
            breakpoint = null;
            break;
          // Special breakpoint to help with styling on extra small devices if needed
          case currentWidth <= BREAKPOINT.XS:
            breakpoint = 'xs';
            break;
        }

        return { type: setBreakpoint.type, breakpoint };
      })
    )
  );

  handleResizeScreensize$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(handleResize),
      map(({ width }) => {
        // While bootstraping the app, passed width argument can be 0,
        // fallback to viewport ruler in that case
        const currentWidth = width || this.viewport.getViewportSize().width;

        let screensize: string;
        if (currentWidth >= DESKTOP_BREAKPOINT) {
          screensize = 'desktop';
        } else {
          screensize = 'mobile';
        }

        return { type: setScreensize.type, screensize };
      })
    )
  );

  constructor(
    private actions$: Actions,
    private viewport: ViewportRuler
  ) {}
}
