import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfinitePaginationComponent } from './infinite-pagination.component';
import { PaginateDirective } from './paginate.directive';
import { IonicModule } from '@ionic/angular';
import { ErrorBoxComponent } from '../error-box/error-box.component';
import { SkeletonComponent } from '../skeleton/skeleton.component';
import { EmptyBoxComponent } from '../empty-box/empty-box.component';

@NgModule({
  declarations: [InfinitePaginationComponent, PaginateDirective],
  imports: [
    CommonModule,
    IonicModule,
    ErrorBoxComponent,
    EmptyBoxComponent,
    SkeletonComponent
  ],
  exports: [
    InfinitePaginationComponent,
    PaginateDirective,
    ErrorBoxComponent,
    EmptyBoxComponent,
    SkeletonComponent
  ]
})
export class InfinitePaginationModule {}
