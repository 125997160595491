import {
  SET_CURRENT_EVENTS_FILTERS,
  SetCurrentEventsFiltersAction
} from './events.actions';
import { EventsFiltersState, initialState } from './events.state';

export const eventsFilterReducers = (
  state: EventsFiltersState = initialState,
  action: Actions
): EventsFiltersState => {
  let newState: EventsFiltersState;
  switch (action.type) {
    case SET_CURRENT_EVENTS_FILTERS:
      newState = {
        ...state,
        currentEventsFilters: action.payload
      };
      break;
    default:
      newState = state;
  }

  return newState;
};

export type Actions = SetCurrentEventsFiltersAction;
