import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthenticatedGuard {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivateChild(
    _: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authenticationService.isLoggedIn().pipe(
      map(logged => {
        if (logged) {
          return true;
        } else {
          return this.router.createUrlTree(['/login'], {
            queryParams: {
              redirectTo: routerState.url
            }
          });
        }
      })
    );
  }
}

// new function guard that does not use deprecated CanActivate or CanActivateChild
export const authenticatedGuard: CanActivateFn = (_, state) => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);

  return authenticationService.isLoggedIn().pipe(
    map(logged => {
      if (logged) {
        return true;
      } else {
        return router.createUrlTree(['/login'], {
          queryParams: { redirectTo: state.url }
        });
      }
    })
  );
};
