import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaybeExternalRouterLinkDirective } from './maybe-external-router-link.directive';
import { AsteriskForRequiredFormControlsDirective } from './asterisk-for-required-formcontrols.directive';
import { ResponsiveColsDirective } from './responsive/responsive-columns.directive';
import { ResponsiveDirective } from './responsive/responsive.directive';
import { MobileOnlyDirective } from './responsive/mobile-only.directive';
import { DesktopOnlyDirective } from './responsive/desktop-only.directive';
import { AddClassIfHasContentDirective } from './add-class-if-has-content.directive';
import { AutofillDirective } from './autofill.directive';
import { IfActiveMembershipDirective } from './if-active-membership.directive';

@NgModule({
  declarations: [
    MaybeExternalRouterLinkDirective,
    AsteriskForRequiredFormControlsDirective,
    ResponsiveColsDirective,
    ResponsiveDirective,
    MobileOnlyDirective,
    DesktopOnlyDirective,
    AddClassIfHasContentDirective,
    AutofillDirective
  ],
  imports: [CommonModule, IfActiveMembershipDirective],
  exports: [
    MaybeExternalRouterLinkDirective,
    AsteriskForRequiredFormControlsDirective,
    ResponsiveColsDirective,
    ResponsiveDirective,
    MobileOnlyDirective,
    DesktopOnlyDirective,
    AddClassIfHasContentDirective,
    AutofillDirective,
    IfActiveMembershipDirective
  ]
})
export class DirectivesModule {}
