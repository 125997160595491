import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { getCurrentTenant } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TenantSelectedGuard {
  constructor(
    private store: Store,
    private router: Router
  ) {}

  canActivateChild(
    _: ActivatedRouteSnapshot,
    __: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(getCurrentTenant).pipe(
      filter(t => t !== 'loading'),
      map(t => {
        return !t ? this.router.createUrlTree(['/onboarding']) : true;
      })
    );
  }
}

// new function guard that does not use deprecated CanActivate or CanActivateChild
export const tenantSelectedGuard = () => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(getCurrentTenant).pipe(
    filter(t => t !== 'loading'),
    map(t => {
      return !t ? router.createUrlTree(['/onboarding']) : true;
    })
  );
};
