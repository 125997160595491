import { ArticleEvent } from '@flink-legacy/core/declarations/article.interface';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  Article,
  ArticlePoll,
  ArticleType
} from '@flink-legacy/interfaces/article';
import {
  ArticleRepository,
  ArticleRepositoryParams
} from '@flink-legacy/shared/repositories/article.repository';
import { Like } from '@flink-legacy/core/declarations/like.interface';

import { PaginatedAbstractService } from './paginated.abstract.service';

import { ModalService } from '@bling-fe/shared/modals/base-modal';
import { Router } from '@angular/router';
import { ShareService } from './share.service';
import { select, Store } from '@ngrx/store';
import { marker as _ } from '@bling-fe/shared/utils';
import { Tenant } from '@flink-legacy/core/declarations/tenant.interface';
import { getCurrentTenant } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { getCurrentUser } from '@flink-legacy/core/states/user-state/user.selectors';
import { User } from '@flink-legacy/core/declarations/user.interface';
import { ReportService } from './report.service';
import { ArticleDetail } from '@flink-legacy/core/declarations/article-detail.interface';
import { CalendarService } from './calendar.service';
import {
  OptionsModalAction,
  OptionsModalProps,
  SharedModalsUiOptionsModalComponent
} from '@flink-legacy/modals/shared-modals-ui-options-modal/shared-modals-ui-options-modal.component';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PostService extends PaginatedAbstractService<ArticleRepositoryParams> {
  constructor(
    private articleRepository: ArticleRepository,
    private modalService: ModalService,
    private router: Router,
    private shareService: ShareService,
    private tenantStore: Store<Tenant>,
    private userStore: Store<User>,
    private reportService: ReportService,
    private calendarService: CalendarService,
    private location: Location
  ) {
    super(articleRepository);
  }

  vote(post: ArticlePoll, option: number) {
    return this.articleRepository.vote(post.id, option).pipe(
      tap(article => {
        this.data.replaceItem(article.id, article);
      })
    );
  }

  /**
   * If Like object is returned, like was added.
   * If null is returned, like was removed.
   */
  toggleLike(id: number): Observable<Like> {
    return this.articleRepository.like(id).pipe(
      tap(liked => {
        this.data.replaceItem(id, article => ({
          ...article,
          like_count: liked ? article.like_count + 1 : article.like_count - 1,
          liked: !!liked
        }));
      })
    );
  }

  togglePin(id: number): Observable<ArticleDetail> {
    return this.articleRepository.pin(id).pipe(
      tap(async post => {
        // the last pinned important item go to the first
        if (post.pinned) {
          if (post.important) {
            this.data.emitUpdateItems(items => {
              const i = items.findIndex(p => p.id === post.id);
              items.splice(i, 1);
              return [post, ...items];
            });
          } else {
            // the last pinnned non important item go to the one after the last pinned important
            this.data.emitUpdateItems(items => {
              const i = items.findIndex(p => p.id === post.id);
              const pinnedImportantItemcount = items
                .filter(p => p.important)
                .filter(p => p.pinned).length;

              items.splice(i, 1);
              items.splice(pinnedImportantItemcount, 0, post);
              return items;
            });
          }
        } else {
          // on unpinning we need to do refetch - we don't know on which page it lands
          await this.refetch();
        }
      })
    );
  }

  commentAdded(id: number) {
    this.data.replaceItem(id, it => ({
      ...it,
      comment_count: it.comment_count + 1
    }));
  }

  commentDeleted(id: number) {
    this.data.replaceItem(id, it => ({
      ...it,
      comment_count: it.comment_count - 1
    }));
  }

  async showAndHandlePostOptions(
    post: Article,
    groupParams?: { groupId: number; groupTitle: string }
  ): Promise<void> {
    const actionsEnabled: OptionsModalAction[] = ['share'];

    this.userStore.pipe(select(getCurrentUser)).subscribe(u => {
      const isAdmin = u?.admin || u?.super_admin;
      const isPublisher = u?.id === post?.user?.id;
      // admin or super admin can delete with a reason any post
      // also onse marked as important
      if (isPublisher) {
        actionsEnabled.push('delete');
      } else if (isAdmin) {
        actionsEnabled.push('deleteWithReason');
      }
      // post owner can edit their posts
      // admin or super admin can edit posts mared as important
      if (
        (isPublisher || (isAdmin && post.important)) &&
        post.article_type !== ArticleType.POLL
      ) {
        actionsEnabled.push('edit');
      }
      // publisher cannot report his/her own post
      if (!isPublisher) {
        actionsEnabled.push('flag', 'block');
      }
      if (u?.id === post?.user?.id && post.article_type === 'event') {
        actionsEnabled.push('duplicate');
      }
    });
    // only event can add the time to calendar
    if (post.article_type === 'event') {
      actionsEnabled.push('calendar');
    }

    const basicComponentProps: OptionsModalProps = {
      title: _('MODALS.OPTIONS.POST.TITLE'),
      deleteModalProps: {
        title:
          post.article_type == ArticleType.EVENT
            ? _('MODALS.OPTIONS.EVENT.DELETE_TITLE')
            : _('MODALS.OPTIONS.POST.DELETE_TITLE'),
        description:
          post.article_type == ArticleType.EVENT
            ? _('MODALS.OPTIONS.EVENT.DELETE_DESCRIPTION')
            : _('MODALS.OPTIONS.POST.DELETE_DESCRIPTION')
      },
      flagModalProps: {
        title: _('MODALS.OPTIONS.POST.REPORT.TITLE'),
        confirmLabel: _('MODALS.OPTIONS.POST.REPORT.REPORT')
      },
      blockModalProps: {
        title: _('MODALS.OPTIONS.POST.BLOCK.TITLE'),
        confirmLabel: _('MODALS.OPTIONS.POST.BLOCK.BLOCK')
      },
      actionsEnabled
    };

    const eventExtraProps: OptionsModalProps = {
      flagModalProps: {
        title: _('PAGES.EVENT_DETAIL.OPTION_MODAL.REPORT_CONFIRM.TITLE'),
        confirmLabel: _('PAGES.EVENT_DETAIL.OPTION_MODAL.REPORT_CONFIRM.REPORT')
      },
      blockModalProps: {
        title: _('PAGES.EVENT_DETAIL.OPTION_MODAL.BLOCK_CONFIRM.TITLE'),
        confirmLabel: _('PAGES.EVENT_DETAIL.OPTION_MODAL.BLOCK_CONFIRM.BLOCK')
      }
    };

    // event has specific comfirmation text
    const finalProps: OptionsModalProps =
      post.article_type === 'event'
        ? { ...basicComponentProps, ...eventExtraProps }
        : basicComponentProps;

    const res = await this.modalService.presentAndGetResult(
      SharedModalsUiOptionsModalComponent,
      finalProps
    );
    if (!res) {
      return;
    }

    switch (res.action) {
      case 'edit':
        if (post.article_type === ArticleType.EVENT) {
          this.router.navigate(['/edit/event', post.id], {
            queryParams: groupParams
          });
        } else {
          this.router.navigate(['/edit/post', post.article_type, post.id], {
            queryParams: groupParams
          });
        }
        break;
      case 'duplicate':
        if (this.router.url.includes('calendar')) {
          this.router.navigate(['/new/event'], {
            queryParams: {
              ...groupParams,
              duplicateEventId: post.id,
              redirectTo: 'calendar'
            }
          });
        } else {
          this.router.navigate(['/new/event'], {
            queryParams: { ...groupParams, duplicateEventId: post.id }
          });
        }
        break;
      case 'delete':
        this.sendDeleteRequest(post);
        break;
      case 'deleteWithReason':
        this.sendDeleteRequest(post, res.reason_for_action);
        break;
      case 'share':
        const url = await firstValueFrom(
          this.tenantStore
            .select(getCurrentTenant)
            .pipe(
              map(t => t !== 'loading' && t.tenant_setting.app_frontend_url)
            )
        );
        this.shareService.share({
          title: post.title,
          url: `${url}/post/${post.id}`
        });
        break;
      case 'flag':
        this.reportService.handleReport({
          itemId: post?.id,
          itemType: 'Article',
          reportType: 'flag',
          reason: res.reason_for_action
        });
        break;
      case 'block':
        this.reportService.handleReport({
          itemId: post?.id,
          itemType: 'Article',
          reportType: 'block',
          reason: res.reason_for_action
        });
        break;
      case 'calendar':
        this.calendarService.createCalendarEvent(post as ArticleEvent);
        break;
    }
  }

  sendDeleteRequest(post, modalMessage?) {
    this.delete(post.id, { reason_for_deletion: modalMessage }).subscribe(_ => {
      // the modal is closed at this point, all good
    });
  }
}
