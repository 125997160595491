import { createReducer, on } from '@ngrx/store';
import { SetMessageNotifications } from './message-notifications.actions';
import { initialState } from './message-notifications.state';

export const messageNotificationsReducers = createReducer(
  initialState,
  on(SetMessageNotifications, (state, { notifications }) => ({
    ...state,
    messageNotifications: notifications
  }))
);
