import { Injectable, Injector } from '@angular/core';
import {
  Settlement,
  SettlementFilterParams
} from '@flink-legacy/core/declarations/settlement.interface';

import {
  DefineRepositoryParams,
  RepositoryAbstract
} from '@flink-legacy/shared/repositories/repository.abstract';

export type SettlementRepositoryParams = DefineRepositoryParams<
  Settlement,
  {
    queryParams: SettlementFilterParams;
  }
>;

@Injectable({
  providedIn: 'root'
})
export class SettlementRepository extends RepositoryAbstract<SettlementRepositoryParams> {
  constructor(injector: Injector) {
    super(`/settlements`, injector);
  }
}
