import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Fetchable } from '@flink-legacy/core/declarations/fetchable.interface';
import { Paginated } from '@flink-legacy/core/declarations/paginated.interface';
import { IonInfiniteScroll, RefresherEventDetail } from '@ionic/angular';

@Component({
  selector: 'fl-infinite-pagination[data]',
  templateUrl: './infinite-pagination.component.html',
  styleUrls: ['./infinite-pagination.component.scss'],
  standalone: false
})
export class InfinitePaginationComponent<T, E> implements OnChanges {
  @Input() data: Fetchable<Paginated<T>, E>;
  @Output() fetchNext = new EventEmitter<void>();
  @Output() refreshPage = new EventEmitter<RefresherEventDetail>();
  @ViewChild('postsInfiniteScroll') postsInfiniteScroll: IonInfiniteScroll;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['data']) {
      const curr = changes?.['data'].currentValue as Fetchable<Paginated<T>, E>;

      if (this.postsInfiniteScroll) {
        // disabling/enabling loader when all pages loaded
        if (curr.data?.current_page >= curr.data?.total_pages) {
          this.postsInfiniteScroll.disabled = true;
        } else {
          this.postsInfiniteScroll.disabled = false;
        }

        // let loader know that next page loaded
        if (curr.state === 'ready' || curr.state === 'error') {
          this.postsInfiniteScroll.complete();
        }
      }
    }
  }

  public handleInfiniteLoad() {
    this.fetchNext.emit();
  }

  doRefresh(e) {
    if (e?.target) {
      this.refreshPage.emit(e.target);
    }
  }
}
